import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/elixir-development/Banner"
import Feel from "../components/elixir-development/Feel"
import Lead from "../components/elixir-development/Lead"
import Testimonials from "../components/elixir-development/Testimonials"
import Testimonials2 from "../components/elixir-development/Testimonials2"
import Tools from "../components/elixir-development/Tools"
import WhyChoose from "../components/elixir-development/WhyChoose"
import WhyHire from "../components/elixir-development/WhyHire"
import WorkPortfolio from "../components/common/WorkPortfolio"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
    />
  )
}
const ElixirPage = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const lead = data?.strapiPage?.sections[1]
  const whyHire = data?.strapiPage?.sections[2]
  const choose = data?.strapiPage?.sections[3]
  const feel = data?.strapiPage?.sections[4]
  const models = data?.strapiPage?.sections[5]
  const testimonial = data?.strapiPage?.sections[7]
  const tools = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]
  const testimonials = data?.testimonials?.sections[0]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <Lead strapiData={lead} />
      <WhyHire strapiData={whyHire} />
      <WhyChoose strapiData={choose} />
      <div className="mt-0">
        <EngagementModels strapiData={models} />
      </div>
      <Tools strapiData={tools} />
      <Feel strapiData={feel} pageName="Hire Elixir Developer" />
      <WorkPortfolio
        portfolioHead={"Our Project Portfolio Showcase"}
        portfolioDesc={
          "Engage in our tech portfolio showcase and explore the extraordinary mastery and excellence we put in our work that sets us apart."
        }
      />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} testimonial={testimonial} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}

      <Faqs strapiData={faqs} pageName="Hire Elixir Developer" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "elixir-1" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default ElixirPage

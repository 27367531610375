// extracted by mini-css-extract-plugin
export var btn = "WhyChoose-module--btn--36c6d";
export var cards = "WhyChoose-module--cards--5cbdb";
export var description = "WhyChoose-module--description--aa05c";
export var hire = "WhyChoose-module--hire--3ee94";
export var hireCyber = "WhyChoose-module--hireCyber--82b0b";
export var hireImg = "WhyChoose-module--hireImg--a6ef5";
export var hireImg2 = "WhyChoose-module--hireImg2--2c320";
export var talent = "WhyChoose-module--talent--e2492";
export var teamBtn = "WhyChoose-module--teamBtn--e1ffd";
export var text = "WhyChoose-module--text--1c5c9";
export var tick = "WhyChoose-module--tick--76a8a";
// extracted by mini-css-extract-plugin
export var Frame = "Tools-module--Frame--6c2c5";
export var Rec = "Tools-module--Rec--99661";
export var advance = "Tools-module--advance--539b5";
export var cir = "Tools-module--cir--9325a";
export var dots = "Tools-module--dots--83111";
export var for1 = "Tools-module--for1--7a28b";
export var heading = "Tools-module--heading--9bdae";
export var iconssCard = "Tools-module--iconssCard--441f5";
export var techBg = "Tools-module--techBg--1f495";
export var techDot = "Tools-module--techDot--da371";
export var techIcon = "Tools-module--techIcon--a780c";
export var techImg = "Tools-module--techImg--d4fd6";
export var technologyIcon = "Tools-module--technologyIcon--bf621";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import line from "../../images/Hire-Elixir/line.svg"
import line2 from "../../images/Hire-Elixir/line2.svg"
import minus from "../../images/Hire-Elixir/minus.svg"
import plus from "../../images/Hire-Elixir/plus.svg"
import "./Reasons.scss"
import * as styles from "./WhyHire.module.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div>{children}</div>
        <div type="button">
          {isCurrentEventKey ? (
            <img src={minus} alt="icon" />
          ) : (
            <img src={plus} alt="icon" />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const WhyHire = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)
  // const image2 = getImage(strapiData?.secImages[1]?.localFile)

  return (
    <div className={styles.hireCyber}>
      {/* {image ? (
        <GatsbyImage
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          image={image}
          alt={strapiData?.title}
        />
      ) : (
        <img
          src={strapiData?.secImages[0]?.localFile?.publicURL}
          alt={strapiData?.secImages[0]?.alternativeText}
          decoding="async"
          loading="lazy"
          className={styles.hireImg}
        />
      )} */}
      <Container>
        <Row className="gap-30">
          <Col
            lg={{ span: 5, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
                className={styles.hireImg}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
                className={styles.hireImg}
              />
            )}
          </Col>
          <Col
            lg={{ span: 7, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hire}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={styles.hireImg2}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={strapiData?.title}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
            <img
              src={line}
              className={styles.line}
              decoding="async"
              loading="lazy"
              alt="icon"
            />
            <img
              src={line2}
              className={styles.line2}
              decoding="async"
              loading="lazy"
              alt="icon"
            />
            <hr className={styles.hr} />
            <Accordion>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="elixir">
                    {strapiData?.cards &&
                      strapiData?.cards?.map(
                        (e, i) =>
                          i < 3 && (
                            <Card key={i} border="light">
                              <ContextAwareToggle eventKey={`ek-${i}`}>
                                <div className={styles.subTitle}>
                                  <h3 className="mb-0 py-2" type="button">
                                    {e?.title}
                                  </h3>
                                </div>
                              </ContextAwareToggle>
                              <Accordion.Collapse eventKey={`ek-${i}`}>
                                <Card.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: e?.subTitle,
                                    }}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                              <hr className={styles.hr} />
                            </Card>
                          )
                      )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="elixir">
                    {strapiData?.cards &&
                      strapiData?.cards?.map(
                        (e, i) =>
                          i > 2 && (
                            <Card key={i} border="light">
                              <ContextAwareToggle eventKey={`ek-${i}`}>
                                <div className={styles.subTitle}>
                                  <h3 className="mb-0 py-2" type="button">
                                    {e?.title}
                                  </h3>
                                </div>
                              </ContextAwareToggle>
                              <Accordion.Collapse eventKey={`ek-${i}`}>
                                <Card.Body>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: e?.subTitle,
                                    }}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                              <hr className={styles.hr} />
                            </Card>
                          )
                      )}
                  </div>
                </Col>
              </Row>
            </Accordion>
            <img
              src={line}
              className={styles.line}
              decoding="async"
              loading="lazy"
              alt="icon"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyHire

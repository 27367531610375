// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--1e03e";
export var circleTes = "Testimonials2-module--circleTes--e3d3f";
export var content = "Testimonials2-module--content--53a03";
export var des = "Testimonials2-module--des--90fe5";
export var iconContainer = "Testimonials2-module--iconContainer--ec486";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--77a7e";
export var personBg = "Testimonials2-module--personBg--0f884";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--a26be";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--5b840";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--0f1da";
export var row = "Testimonials2-module--row--d3aa8";
export var section = "Testimonials2-module--section--40aaf";
export var tesBtn = "Testimonials2-module--tesBtn--7702a";
export var tesDots = "Testimonials2-module--tesDots--3f3f6";
export var tesRec = "Testimonials2-module--tesRec--1531c";
export var testimonials = "Testimonials2-module--testimonials--3fb9b";
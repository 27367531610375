// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--45fbd";
export var clientDots2 = "Testimonials-module--clientDots2--16f56";
export var content = "Testimonials-module--content--db9e6";
export var dots3 = "Testimonials-module--dots3--580ab";
export var hireArrow = "Testimonials-module--hireArrow--c26f5";
export var iconContainer = "Testimonials-module--iconContainer--272ba";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--e2166";
export var imgBtn = "Testimonials-module--imgBtn--7560d";
export var person = "Testimonials-module--person--a4775";
export var personBg = "Testimonials-module--personBg--eb1ea";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--e4d6d";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--eabe8";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--2ecf6";
export var row = "Testimonials-module--row--bd804";
export var section = "Testimonials-module--section--32a14";
export var staffRec = "Testimonials-module--staffRec--235bf";
export var testName = "Testimonials-module--testName--0329e";
export var testimonials = "Testimonials-module--testimonials--3b718";
export var testimonialsContent = "Testimonials-module--testimonialsContent--0a13f";
export var vector2 = "Testimonials-module--vector2--c6148";